import mock from "../mock"
const data = [
  {
    id:"11",
    effective_date:"04/12/2019",
    type:"INVESTMENT",
    amt: "10000",
    source: "12345",
    target: "2548",
    note: 'Investment in 043436292',
    status: 'Active'
  },
  {
    id:"15",
    effective_date:"04/04/2020",
    type:"INVESTMENT",
    amt: "250000",
    source: "58745",
    target: "589625",
    note: 'Investment in 4568515',
    status: 'Active'
  },
  {
    id:"18",
    effective_date:"04/05/2020",
    type:"INVESTMENT",
    amt: "10000",
    source: "12345",
    target: "2548",
    note: 'Investment in 043436292',
    status: 'Active'
  },
  {
    id:"22",
    effective_date:"18/05/2020",
    type:"INVESTMENT",
    amt: "140000",
    source: "12345",
    target: "2548",
    note: 'Investment in 043436292',
    status: 'Active'
  },
]
mock.onGet("/api/transactions/data").reply(200, {
  data
})
