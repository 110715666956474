// var base_url = "http://52.33.39.13/semble_api/api/v1/"; /*semble*/
var base_url = "https://loan.semble.com/semble_api/api/v1/"; /*semble*/
//var base_url = "http://192.168.0.122/semble/api/v1/"; /*semble*/
// const base_url = "https://appspunditinfotech.com/semble/api/v1/"; /*semble*/



export class AccountServiceImpl {
         /* USED API START */

         investor_register(
           email,
           first_name,
           last_name,
           mobile_no,
           cpassword,
           password,
           type,
           address_1,
           address_2,
           city,
           state,
           country,
           pin_code,
           accrInv
         ) {
           var details = {
             email: email,
             first_name: first_name,
             last_name: last_name,
             mobile_no: mobile_no,
             cpassword: cpassword,
             password: password,
             type: type,
             address_1: address_1,
             address_2: address_2,
             city: city,
             state: state,
             country: country,
             pin_code: pin_code,
             accred: accrInv,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor_register", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         login(account_type, email, password) {
           var details = {
             // account_type: account_type,
             // email: email,
             email: email,
             password: password,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "login", {
               method: "POST",
               // headers:{
               // 	 "Accept" : "application/json",
               // 	  "Content-Type" : "application/json"
               // 	  },
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         create_loan(
           access_token,
           email,
           first_name,
           last_name,
           contact_name,
           mobile_no,
           type,
           address_1,
           address_2,
           street,
           city,
           state,
           country,
           pinCode,
           password,
           cpassword,
           issuer_name,
           loan_originator,
           loan_amt,
           loan_min_amt,
           loan_term_month,
           max_return_rate,
           min_invest_amt,
           borrower_id,
           project_revenue,
           notes
         ) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
             email: email,
             first_name: first_name,
             last_name: last_name,
             contact_name: contact_name,
             mobile_no: mobile_no,
             type: type,
             address_1: address_1,
             address_2: address_2,
             street: street,
             city: city,
             state: state,
             country: country,
             pin_code: pinCode,
             password: password,
             cpassword: cpassword,
             issuer_name: issuer_name,
             loan_originator: loan_originator,
             loan_amt: loan_amt,
             loan_min_amt: loan_min_amt,
             loan_term_month: loan_term_month,
             max_return_rate: max_return_rate,
             min_invest_amt: min_invest_amt,
             project_revenue: project_revenue,
             notes: notes,
           };

           // console.log("Details:: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/create", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_loan_list(access_token, status) {
           var details = {
             access_token: access_token,
             status: status,
           };

           //console.log("details:::" + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_loan_list_multiple(access_token, status, from, borrower_id) {
           var details;

           if (from === "") {
             details = {
               access_token: access_token,
               status: status,
             };
           } else {
             details = {
               access_token: access_token,
               status: status,
               borrower_id: borrower_id,
             };
           }

           //console.log("details:::" + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_loan_list_details(
           access_token,
           status,
           borrower_id,
           loan_id,
           commitment,
           investment,
           repay_trans
         ) {
           var details = {
             access_token: access_token,
             status: status,
             borrower_id: borrower_id,
             loan_id: loan_id,
             commitment: commitment,
             investment: investment,
             repay_trans: repay_trans,
           };

           // console.log("Details:: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_borrower_list(access_token, borrower_id) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "borrower/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   // console.log("login:::" + JSON.stringify(data));
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         delete_borrower(access_token, id) {
           var details = {
             access_token: access_token,
             borrower_id: id,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "borrower/delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_investor_list(
           access_token,
           investor_id,
           commitment,
           investment,
           fund_source
         ) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             commitment: commitment,
             investment: investment,
             fund_source: fund_source,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         delete_investor(access_token, id) {
           var details = {
             access_token: access_token,
             investor_id: id,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         make_commitment(access_token, investor_id, loan_id, array, accred) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             loan_id: loan_id,
             commit_array: array,
             accred: accred,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/investor/commitment", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         /*Email Send during make commitment failed*/
         /*
          email_template_id:2
          loan_id:1012
          investor_id:10
          email_to:satish.office2018@gmail.com
          invest_amt:100
          access_token:c4ca4238a0b923820dcc509a6f75849b48a72691a83740e8005f7e480511d5f77542832d2728b2bf001885da89e40b7af214f068d62fd13129cec1dfcd716fb3
          */
         send_email_notify_admin(
           access_token,
           //  email_template_id,
           loan_id,
           investor_id
           //  email_to,
           //  invest_amt
         ) {
           //  var details = {
           //    access_token: access_token,
           //    // email_template_id: email_template_id,
           //    loan_id: loan_id,
           //    investor_id: investor_id,
           //    // email_to: email_to,
           //    // invest_amt: invest_amt,
           //  };
           var form = new FormData();

           form.append("access_token", access_token);
           form.append("loan_id", loan_id);
           form.append("investor_id", investor_id);

           // console.log("Details:::" + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "sendmailapi", {
               method: "POST",
               body: form,
             })
               .then((response) => {
                 response.json().then((data) => {
                   //  console.log("Details:::" + JSON.stringify(data));
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_make_commitment_data(
           access_token,
           investor_id,
           borrower_id,
           loan_id
         ) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             borrower_id: borrower_id,
             loan_id: loan_id,
           };

           //  console.log("commitment -- details:- " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/public/get_start", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         update_borrower(
           access_token,
           borrower_id,
           email,
           first_name,
           last_name,
           contact_name,
           mobile_no,
           type,
           address_1,
           address_2,
           street,
           city,
           state,
           country,
           rolesSelected,
           pin_code,
           statusSwitch,
           notes
         ) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
             email: email,
             first_name: first_name,
             last_name: last_name,
             contact_name: contact_name,
             mobile_no: mobile_no,
             type: type,
             address_1: address_1,
             address_2: address_2,
             street: street,
             city: city,
             state: state,
             country: country,
             permission: rolesSelected,
             pin_code: pin_code,
             status: statusSwitch,
             notes: notes,
           };

           //console.log("Borrowers::: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "borrower/update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         update_borrower_main(
           access_token,
           borrower_id,
           email,
           first_name,
           last_name,
           mobile_no,
           type,
           address_1,
           address_2,
           street,
           city,
           state,
           country,
           rolesSelected,
           pin_code,
           password,
           confPassword,
           status,
           contactName,
           notes
         ) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
             email: email,
             first_name: first_name,
             last_name: last_name,
             mobile_no: mobile_no,
             type: type,
             address_1: address_1,
             address_2: address_2,
             street: street,
             city: city,
             state: state,
             country: country,
             permission: rolesSelected,
             pin_code: pin_code,
             password: password,
             cpassword: confPassword,
             status: status,
             contact_name: contactName,
             notes: notes,
           };

           //  console.log("Borrowers::: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "borrower/update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         update_investor_main(
           access_token,
           investor_id,
           email,
           first_name,
           last_name,
           mobile_no,
           type,
           address_1,
           address_2,
           street,
           city,
           state,
           country,
           rolesSelected,
           pin_code,
           password,
           confPassword,
           status,
           accrInv,
           notes
         ) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             email: email,
             first_name: first_name,
             last_name: last_name,
             mobile_no: mobile_no,
             type: type,
             address_1: address_1,
             address_2: address_2,
             street: street,
             city: city,
             state: state,
             country: country,
             permission: rolesSelected,
             pin_code: pin_code,
             password: password,
             cpassword: confPassword,
             status: status,
             accred: accrInv,
             notes: notes,
           };

           // console.log("Investor::: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         update_loan(
           access_token,
           loan_id,
           borrower_id,
           issuer_name,
           loan_originator,
           origination_date,
           loan_amt,
           loan_min_amt,
           loan_term_month,
           max_return_rate,
           min_invest_amt,
           ltv,
           debt_coverage_ration,
           ltv_completed,
           is_fund,
           marketing_date,
           loan_valid_states,
           status,
           project_revenue,
           maturity_date
         ) {
           var details = {
             access_token: access_token,
             loan_id: loan_id,
             borrower_id: borrower_id,
             issuer_name: issuer_name,
             loan_originator: loan_originator,
             commencement_date: origination_date,
             loan_amt: loan_amt,
             loan_min_amt: loan_min_amt,
             loan_term_month: loan_term_month,
             max_return_rate: max_return_rate,
             min_invest_amt: min_invest_amt,
             ltv: ltv,
             debt_coverage_ration: debt_coverage_ration,
             ltv_completed: ltv_completed,
             is_fund: is_fund,
             marketing_date: marketing_date,
             loan_valid_states: loan_valid_states,
             status: status,
             project_revenue: project_revenue,
             maturity_date: maturity_date,
           };

           // console.log("Loan Edit :: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         loan_delete(access_token, id, borrower_id) {
           var details = {
             access_token: access_token,
             loan_id: id,
             borrower_id: borrower_id,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         loan_Inv(access_token, investment_id) {
           var details = {
             access_token: access_token,
             investment_id: investment_id,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/admin/investment_delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         /**
          *@Commitments Delete By Admin
          */

         loan_commitment_delete(access_token, commitment_id) {
           var details = {
             access_token: access_token,
             commitment_id: commitment_id,
           };

          //  console.log("Commit Delete details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/admin/commitment_delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         sendMsg_LoanAdmin(access_token, loan_id, title, message, type) {
           var details = {
             access_token: access_token,
             loan_id: loan_id,
             title: title,
             message: message,
             type: type,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "annoucement/add", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         getMsg_LoanAdmin(access_token, loan_id) {
           var details = {
             access_token: access_token,
             loan_id: loan_id,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "annoucement/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         forgotpass_sendemail(email_id) {
           var details = {
             email_id: email_id,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "forget-password", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         email_get(access_token) {
           var details = {
             access_token: access_token,
           };
           //email-template-all
           return new Promise((resolve, reject) => {
             fetch(base_url + "email-template-all", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         email_add(
           access_token,
           name,
           email_subject,
           email_message,
           email_status
         ) {
           var details = {
             access_token: access_token,
             name: name,
             email_subject: email_subject,
             email_message: email_message,
             status: email_status,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "email-template-add", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         email_update(
           access_token,
           id,
           name,
           email_subject,
           email_message,
           email_status
         ) {
           var details = {
             access_token: access_token,
             id: id,
             name: name,
             email_subject: email_subject,
             email_message: email_message,
             status: email_status,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "email-template-update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         email_delete(id, access_token) {
           var details = {
             access_token: access_token,
             id: id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "email-template-delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         create_user_role(access_token, name, user_role) {
           var details = {
             access_token: access_token,
             name: name,
             user_role: user_role,
           };

           // console.log(JSON.stringify(details));
           // console.log(base_url + "user-role/create");

           return new Promise((resolve, reject) => {
             fetch(base_url + "user-role/create", {
               method: "POST",
               // mode: "cors",
               // cache: "no-cache",
               // headers: {
               //   "Content-Type": "application/json"
               // },
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         user_role_list(access_token, is_role, role_id) {
           var details = {
             access_token: access_token,
             is_role: is_role,
             role_id: role_id,
           };

           // console.log(JSON.stringify(details));
           // console.log(base_url + "user-role/create");

           return new Promise((resolve, reject) => {
             fetch(base_url + "user-role/list", {
               method: "POST",
               // mode: "cors",
               // cache: "no-cache",
               // headers: {
               //   "Content-Type": "application/json"
               // },
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         update_user_role(access_token, name, role_id, user_role) {
           var details = {
             access_token: access_token,
             name: name,
             role_id: role_id,
             user_role: user_role,
           };

           //console.log("details: " + JSON.stringify(details));
           // console.log(base_url + "user-role/create");

           return new Promise((resolve, reject) => {
             fetch(base_url + "user-role/update", {
               method: "POST",
               // mode: "cors",
               // cache: "no-cache",
               // headers: {
               //   "Content-Type": "application/json"
               // },
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         user_create(
           access_token,
           email,
           first_name,
           last_name,
           mobile_no,
           password,
           cpassword,
           permission
         ) {
           var details = {
             access_token: access_token,
             email: email,
             first_name: first_name,
             last_name: last_name,
             mobile_no: mobile_no,
             cpassword: cpassword,
             password: password,
             permission: permission,
           };

           //  console.log(JSON.stringify(details));
           // console.log(base_url + "user-role/create");

           return new Promise((resolve, reject) => {
             fetch(base_url + "user/create", {
               method: "POST",
               // mode: "cors",
               // cache: "no-cache",
               // headers: {
               //   "Content-Type": "application/json"
               // },
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         user_list(access_token) {
           var details = {
             access_token: access_token,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "user/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         user_delete(access_token, user_id) {
           var details = {
             access_token: access_token,
             user_id: user_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "user/delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         user_update(
           access_token,
           user_id,
           first_name,
           last_name,
           mobile_no,
           permission,
           password,
           confPassword,
           statusSwitch
         ) {
           var details = {
             access_token: access_token,
             user_id: user_id,
             first_name: first_name,
             last_name: last_name,
             mobile_no: mobile_no,
             permission: permission,
             password: password,
             cpassword: confPassword,
             status: statusSwitch,
           };

           //console.log(JSON.stringify(details));
           // console.log(base_url + "user-role/create");

           return new Promise((resolve, reject) => {
             fetch(base_url + "user/update", {
               method: "POST",
               // mode: "cors",
               // cache: "no-cache",
               // headers: {
               //   "Content-Type": "application/json"
               // },
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         user_change_password(
           access_token,
           user_id,
           old_password,
           cpassword,
           password
         ) {
           var details = {
             access_token: access_token,
             user_id: user_id,
             old_password: old_password,
             cpassword: cpassword,
             password: password,
           };

           // console.log(JSON.stringify(details));
           // console.log(base_url + "user-role/create");

           return new Promise((resolve, reject) => {
             fetch(base_url + "user/change_password", {
               method: "POST",
               // mode: "cors",
               // cache: "no-cache",
               // headers: {
               //   "Content-Type": "application/json"
               // },
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                   // console.log(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         role_delete(access_token, id) {
           var details = {
             access_token: access_token,
             role_id: id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "user-role/delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         fundingSource_create(
           access_token,
           investor_id,
           status,
           BankName,
           AccountNumber,
           RoutingNumber,
           nick_name,
           PrimaryAccountHolder_FirstName,
           PrimaryAccountHolder_LastName,
           PrimaryAccountHolder_SSN,
           SecondaryAccountHolder_FirstName,
           SecondaryAccountHolder_LastName,
           SecondaryAccountHolder_SSN,
           CustodianName,
           CustodianAddress1,
           CustodianAddress2,
           CustodianCity,
           CustodianState,
           CustodianZipcode,
           CustodianTaxID,
           inpAccNumber2,
           type
         ) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             status: status,
             BankName: BankName,
             AccountNumber: AccountNumber,
             RoutingNumber: RoutingNumber,
             nick_name: nick_name,
             PrimaryAccountHolder_FirstName: PrimaryAccountHolder_FirstName,
             PrimaryAccountHolder_LastName: PrimaryAccountHolder_LastName,
             PrimaryAccountHolder_SSN: PrimaryAccountHolder_SSN,
             SecondaryAccountHolder_FirstName: SecondaryAccountHolder_FirstName,
             SecondaryAccountHolder_LastName: SecondaryAccountHolder_LastName,
             SecondaryAccountHolder_SSN: SecondaryAccountHolder_SSN,
             CustodianName: CustodianName,
             CustodianAddress1: CustodianAddress1,
             CustodianAddress2: CustodianAddress2,
             CustodianCity: CustodianCity,
             CustodianState: CustodianState,
             CustodianZipcode: CustodianZipcode,
             CustodianTaxID: CustodianTaxID,
             inpAccNumber2: inpAccNumber2,
             type: type,
           };

           //console.log("DETAILS:: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/fund/create", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         fundingSource_update(
           access_token,
           investor_id,
           funding_id,
           status,
           BankName,
           AccountNumber,
           RoutingNumber,
           nick_name,
           PrimaryAccountHolder_FirstName,
           PrimaryAccountHolder_LastName,
           PrimaryAccountHolder_SSN,
           SecondaryAccountHolder_FirstName,
           SecondaryAccountHolder_LastName,
           SecondaryAccountHolder_SSN,
           CustodianName,
           CustodianAddress1,
           CustodianAddress2,
           CustodianCity,
           CustodianState,
           CustodianZipcode,
           CustodianTaxID,
           inpAccNumber2,
           type
         ) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             funding_id: funding_id,
             status: status,
             BankName: BankName,
             AccountNumber: AccountNumber,
             RoutingNumber: RoutingNumber,
             nick_name: nick_name,
             PrimaryAccountHolder_FirstName: PrimaryAccountHolder_FirstName,
             PrimaryAccountHolder_LastName: PrimaryAccountHolder_LastName,
             PrimaryAccountHolder_SSN: PrimaryAccountHolder_SSN,
             SecondaryAccountHolder_FirstName: SecondaryAccountHolder_FirstName,
             SecondaryAccountHolder_LastName: SecondaryAccountHolder_LastName,
             SecondaryAccountHolder_SSN: SecondaryAccountHolder_SSN,
             CustodianName: CustodianName,
             CustodianAddress1: CustodianAddress1,
             CustodianAddress2: CustodianAddress2,
             CustodianCity: CustodianCity,
             CustodianState: CustodianState,
             CustodianZipcode: CustodianZipcode,
             CustodianTaxID: CustodianTaxID,
             inpAccNumber2: inpAccNumber2,
             type: type,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/fund/update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         fundingSource_delete(access_token, investor_id, funding_id) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             funding_id: funding_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/fund/delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         borrower_register(
           email,
           first_name,
           last_name,
           contact_name,
           mobile_no,
           cpassword,
           password,
           type,
           address_1,
           address_2,
           street,
           city,
           state,
           country,
           pin_code,
           notes
         ) {
           var details = {
             email: email,
             first_name: first_name,
             last_name: last_name,
             contact_name: contact_name,
             mobile_no: mobile_no,
             cpassword: cpassword,
             password: password,
             type: type,
             address_1: address_1,
             address_2: address_2,
             street: street,
             city: city,
             state: state,
             country: country,
             pin_code: pin_code,
             notes: notes,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "borrower_register", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         add_transaction(
           access_token,
           id,
           funding_id,
           type,
           amount,
           effective_date,
           note
         ) {
           var details = {
             access_token: access_token,
             id: id,
             funding_id: funding_id,
             type: type,
             amount: amount,
             effective_date: effective_date,
             note: note,
           };

           //console.log("transaction details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/transaction/add", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_transactions(
           access_token,
           id,
           funding_id,
           investment_id,
           loan_id,
           start_date,
           end_date
         ) {
           var details = {
             access_token: access_token,
             id: id,
             funding_id: funding_id,
             investment_id: investment_id,
             loan_id: loan_id,
             start_date: start_date,
             end_date: end_date,
           };

           //console.log("transaction details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/transaction/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         commit_to_invest(
           access_token,
           id,
           commit_id,
           funding_id,
           invest_amt,
           rate,
           type,
           accred,
           can_invest_more,
           pa_sign,
           status,
           payment_type,
           effective_date,
           note,
           funding_commit
         ) {
           var details = {
             access_token: access_token,
             id: id,
             commit_id: commit_id,
             funding_id: funding_id,
             invest_amt: invest_amt,
             rate: rate,
             type: type,
             accred: accred,
             can_invest_more: can_invest_more,
             pa_sign: pa_sign,
             status: status,
             payment_type: payment_type,
             effective_date: effective_date,
             note: note,
             funding_commit: funding_commit,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/commit-to-invest", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         add_funding_source(
           access_token,
           investor_id,
           type,
           goldstar_ira,
           ira_value,
           ira_type,
           bank_account_entity,
           CustodianName,
           BankName,
           AccountNumber,
           RoutingNumber,
           nick_name,
           PrimaryAccountHolder_FirstName,
           PrimaryAccountHolder_LastName,
           PrimaryAccountHolder_SSN,
           SecondaryAccountHolder_FirstName,
           SecondaryAccountHolder_LastName,
           SecondaryAccountHolder_SSN
         ) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             type: type,
             goldstar_ira: goldstar_ira,
             ira_value: ira_value,
             ira_type: ira_type,
             bank_account_entity: bank_account_entity,
             CustodianName: CustodianName,
             BankName: BankName,
             AccountNumber: AccountNumber,
             RoutingNumber: RoutingNumber,
             nick_name: nick_name,
             PrimaryAccountHolder_FirstName: PrimaryAccountHolder_FirstName,
             PrimaryAccountHolder_LastName: PrimaryAccountHolder_LastName,
             PrimaryAccountHolder_SSN: PrimaryAccountHolder_SSN,
             SecondaryAccountHolder_FirstName: SecondaryAccountHolder_FirstName,
             SecondaryAccountHolder_LastName: SecondaryAccountHolder_LastName,
             SecondaryAccountHolder_SSN: SecondaryAccountHolder_SSN,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/funding/add", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         update_funding_source(
           access_token,
           investor_id,
           type,
           goldstar_ira,
           ira_value,
           ira_type,
           bank_account_entity,
           CustodianName,
           BankName,
           AccountNumber,
           RoutingNumber,
           nick_name,
           PrimaryAccountHolder_FirstName,
           PrimaryAccountHolder_LastName,
           PrimaryAccountHolder_SSN,
           SecondaryAccountHolder_FirstName,
           SecondaryAccountHolder_LastName,
           SecondaryAccountHolder_SSN,
           funding_id
         ) {
           var details = {
             access_token: access_token,
             investor_id: investor_id,
             funding_id: funding_id,
             type: type,
             goldstar_ira: goldstar_ira,
             ira_value: ira_value,
             ira_type: ira_type,
             bank_account_entity: bank_account_entity,
             CustodianName: CustodianName,
             BankName: BankName,
             AccountNumber: AccountNumber,
             RoutingNumber: RoutingNumber,
             nick_name: nick_name,
             PrimaryAccountHolder_FirstName: PrimaryAccountHolder_FirstName,
             PrimaryAccountHolder_LastName: PrimaryAccountHolder_LastName,
             PrimaryAccountHolder_SSN: PrimaryAccountHolder_SSN,
             SecondaryAccountHolder_FirstName: SecondaryAccountHolder_FirstName,
             SecondaryAccountHolder_LastName: SecondaryAccountHolder_LastName,
             SecondaryAccountHolder_SSN: SecondaryAccountHolder_SSN,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/funding/update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         updateProfile(
           access_token,
           login_id,
           first_name,
           last_name,
           mobile_no,
           address_1,
           address_2,
           street,
           city,
           state,
           country,
           pin_code,
           accrInv
         ) {
           var details = {
             access_token: access_token,
             login_id: login_id,
             first_name: first_name,
             last_name: last_name,
             mobile_no: mobile_no,
             address_1: address_1,
             address_2: address_2,
             street: street,
             city: city,
             state: state,
             country: country,
             pin_code: pin_code,
             accred: accrInv,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "profile-update", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         updatePassword(
           access_token,
           login_id,
           old_password,
           password,
           cpassword
         ) {
           var details = {
             access_token: access_token,
             login_id: login_id,
             old_password: old_password,
             password: password,
             cpassword: cpassword,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "change-password", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         loan_repayment(
           access_token,
           action,
           borrower_id,
           loan_id,
           amount,
           trns_date,
           note,
           save_key
         ) {
           var details = {
             access_token: access_token,
             action: action,
             borrower_id: borrower_id,
             loan_id: loan_id,
             amount: amount,
             trns_date: trns_date,
             note: note,
             save_key: save_key,
           };

           //console.log("details: " + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/repayment", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         commit_to_invest_admin(
           access_token,
           id,
           commit_id,
           invest_amt,
           rate,
           type,
           accred,
           can_invest_more,
           pa_sign,
           status,
           note,
           funding_commit,
           invetor_role,
           prior_support_type,
           is_anonymous,
           is_affilated,
           min_invest_amt,
           planned_funding_method,
           netWorth,
           distribution_frequency,
           funding_source_id,
           docsControl,
           docsTitle,
           isFundReceived,
           npaSentDate,
           signedNotes,
           docsToRemove,
           docsID,
           icDate
         ) {
           var form = new FormData();

           form.append("access_token", access_token);
           form.append("id", id);
           form.append("commit_id", commit_id);
           form.append("invest_amt", invest_amt);
           form.append("rate", rate);
           form.append("type", type);
           form.append("accred", accred);
           form.append("can_invest_more", can_invest_more);
           form.append("pa_sign", pa_sign);
           form.append("status", status);
           form.append("note", note);
           form.append("funding_commit", funding_commit);
           form.append("invetor_role", invetor_role);
           form.append("prior_support_type", prior_support_type);
           form.append("is_anonymous", is_anonymous);
           form.append("is_affilated", is_affilated);
           form.append("min_invest_amt", min_invest_amt);
           form.append("planned_funding_method", planned_funding_method);
           form.append("netWorth", netWorth);
           form.append("distribution_frequency", distribution_frequency);
           form.append("funding_source_id", funding_source_id);
           form.append("investor_commencement_date", icDate);

           var j = 0;
           // console.log("Status::: " + JSON.stringify(status));

           for (var i = 0; i < docsControl.length; i++) {
             if (docsID[i] === "0") {
               form.append("doc_name[" + j + "]", docsTitle[i]);
               form.append(
                 "images[" + j + "]",
                 docsControl[i],
                 docsControl[i].name
               );

               //  console.log("TO url:: " + docsControl[i].url);
               //  console.log("TO name:: " + docsControl[i].name);

               j = j + 1;
             }
           }

           if (npaSentDate !== "") {
             form.append("npa_sent_date", npaSentDate);
           }
           if (signedNotes !== "") {
             form.append("signed_note", signedNotes);
           }
           if (isFundReceived !== "") {
             form.append("fund_received", isFundReceived);
           }

           for (var i = 0; i < docsToRemove.length; i++) {
             form.append("remove_images[" + i + "]", docsToRemove[i]);
           }

           // console.log("Token::" + access_token);
           // console.log("id::" + id);
           // console.log("commit_id::" + commit_id);
           // console.log("invest_amt::" + invest_amt);
           // console.log("rate::" + rate);
           // console.log("type::" + type);
           // console.log("accred::" + accred);
           // console.log("can_invest_more::" + can_invest_more);
           // console.log("pa_sign::" + pa_sign);
           // console.log("status::" + status);
           // console.log("note::" + note);
           // console.log("funding_commit::" + funding_commit);
           // console.log("invetor_role::" + invetor_role);
           // console.log("prior_support_type::" + prior_support_type);
           // console.log("is_anonymous::" + is_anonymous);
           // console.log("is_affilated::" + is_affilated);
           // console.log("min_invest_amt::" + min_invest_amt);
           // console.log("planned_funding_method::" + planned_funding_method);
           // console.log("netWorth::" + netWorth);
           // console.log("distribution_frequency::" + distribution_frequency);
           // console.log("funding_source_id::" + funding_source_id);
           // console.log("investor_commencement_date::" + icDate);

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/admin/commit-to-invest", {
               method: "POST",
               body: form,
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         investment_update(
           access_token,
           id,
           investment_id,
           invest_amt,
           rate,
           type,
           accred,
           can_invest_more,
           pa_sign,
           status,
           note,
           funding_commit,
           invetor_role,
           prior_support_type,
           is_anonymous,
           is_affilated,
           min_invest_amt,
           planned_funding_method,
           netWorth,
           distribution_frequency,
           funding_source_id,
           docsControl,
           docsTitle,
           isFundReceived,
           npaSentDate,
           signedNotes,
           docsToRemove,
           docsID,
           icDate
         ) {
           var form = new FormData();

           form.append("access_token", access_token);
           form.append("id", id);
           form.append("investment_id", investment_id);
           form.append("invest_amt", invest_amt);
           form.append("rate", rate);
           form.append("type", type);
           form.append("accred", accred);
           form.append("can_invest_more", can_invest_more);
           form.append("pa_sign", pa_sign);
           form.append("status", status);
           form.append("note", note);
           form.append("funding_commit", funding_commit);
           form.append("invetor_role", invetor_role);
           form.append("prior_support_type", prior_support_type);
           form.append("is_anonymous", is_anonymous);
           form.append("is_affilated", is_affilated);
           form.append("min_invest_amt", min_invest_amt);
           form.append("planned_funding_method", planned_funding_method);
           form.append("netWorth", netWorth);
           form.append("distribution_frequency", distribution_frequency);
           form.append("funding_source_id", funding_source_id);
           form.append("investor_commencement_date", icDate);

           var j = 0;

           for (var i = 0; i < docsControl.length; i++) {
             if (docsID[i] === "0") {
               form.append("doc_name[" + j + "]", docsTitle[i]);
               form.append(
                 "images[" + j + "]",
                 docsControl[i],
                 docsControl[i].name
               );
               // console.log("TO ADD:: " + j.toString());

               j = j + 1;
             }
           }

           if (npaSentDate !== "") {
             form.append("npa_sent_date", npaSentDate);
           }
           if (signedNotes !== "") {
             form.append("signed_note", signedNotes);
           }
           if (isFundReceived !== "") {
             form.append("fund_received", isFundReceived);
           }

           for (var i = 0; i < docsToRemove.length; i++) {
             form.append("remove_images[" + i + "]", docsToRemove[i]);
           }

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/admin/investment_update", {
               method: "POST",
               body: form,
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         investor_dashboard(access_token, id) {
           var details = {
             access_token: access_token,
             investor_id: id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "investor/dashboard", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_loan_list_by_borrower(access_token, status, borrower_id) {
           var details = {
             access_token: access_token,
             status: status,
             borrower_id: borrower_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "loan/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         manage_listing(access_token, manage_status) {
           var details = {
             access_token: access_token,
             manage_status: manage_status,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/listings", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         uploadPageContent(
           access_token,
           borrower_id,
           loan_id,
           title,
           navigation_title,
           logo,
           type,
           array,
           prospectusTitle,
           prospectus,
		   shared_title,
		   shared_desc,
		   shared_img
         ) {
          
           var form = new FormData();

           form.append("access_token", access_token);
           form.append("borrower_id", borrower_id);
           form.append("loan_id", loan_id);
           form.append("title", title);
           // form.append("navigation_title", navigation_title);
           form.append("param_link", navigation_title);

           form.append("logo", logo, logo.name);

           form.append("status", type);
           form.append("content", JSON.stringify(array));

           form.append("prospectus_title", prospectusTitle);
           form.append("prospectus", prospectus, prospectus.name);
		   
          form.append("social_title", shared_title);
          form.append("social_description", shared_desc);
          if(shared_img!=""){
            form.append("social_image", shared_img, shared_img.name);
          }
		   

           //console.log("FORM:: " + JSON.stringify(form));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/listing/create", {
               method: "POST",
               body: form,
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         uploadImgDoc(
           access_token,
           borrower_id,
           loan_id,
           title,
           document_file
         ) {
           var form = new FormData();

           form.append("access_token", access_token);
           form.append("borrower_id", borrower_id);
           form.append("loan_id", loan_id);
           form.append("title", title);

           form.append("document_file", document_file, document_file.name);

           //  console.log(
           //    "UploadImg Form:::" + access_token
           //    +"ID:::" + borrower_id
           //    +"Loan ID:::" + loan_id
           //    +"Title:::" + title
           //  );

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/listing/upload_document", {
               method: "POST",
               body: form,
             })
               .then((response) => {
                 response.json().then((data) => {
                   //  console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         upload_list(access_token, borrower_id, loan_id) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
             loan_id: loan_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/listing/uploads_list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         getPublicListingDetails(public_url, access_token) {
           var details;
           var url;

           if (access_token === "") {
             details = {
               public_url: public_url,
             };
             url = "public/listing";
           } else {
             details = {
               public_url: public_url,
               access_token: access_token,
             };
             url = "preview/listing";
           }

           //  console.log("Details::: " + JSON.stringify(details));
           //  console.log("Public URL::: " + public_url);

           return new Promise((resolve, reject) => {
             fetch(base_url + url, {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         deleteDoc(access_token, borrower_id, loan_id, file_id) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
             loan_id: loan_id,
             file_id: file_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/listing/file_delete", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         add_TC(access_token, borrower_id, loan_id, content) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
             loan_id: loan_id,
             content: content,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/tc/add", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_TC(access_token, borrower_id, loan_id) {
           var details = {
             access_token: access_token,
             borrower_id: borrower_id,
             loan_id: loan_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/tc/list", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         getListingData(access_token, loan_id, id, borrower_id) {
           var details = {
             access_token: access_token,
             id: id,
             loan_id: loan_id,
             borrower_id: borrower_id,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/listing_edit", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         updatePageContent(
           access_token,
           borrower_id,
           loan_id,
           title,
           navigation_title,
           logo,
           type,
           array,
           prospectusTitle,
           prospectus,
           id,
		   shared_title,
		   shared_desc,
		   shared_img
         ) {
           var form = new FormData();

           form.append("id", id);
           form.append("access_token", access_token);
           form.append("borrower_id", borrower_id);
           form.append("loan_id", loan_id);
           form.append("title", title);
           // form.append("navigation_title", navigation_title);
           form.append("param_link", navigation_title);

           if (logo !== "") {
             form.append("logo", logo, logo.name);
           }
		   
		   if (shared_img !== "") {
             form.append("social_image", shared_img, shared_img.name);
           }
			form.append("social_title", shared_title);
           form.append("social_description", shared_desc);
			
			
           form.append("status", type);
           form.append("content", JSON.stringify(array));

           form.append("prospectus_title", prospectusTitle);
           
           if (prospectus !== "") {
             form.append("prospectus", prospectus, prospectus.name);
           }

           //console.log("FORM:: " + JSON.stringify(form));

           return new Promise((resolve, reject) => {
             fetch(base_url + "loans/listing/update", {
               method: "POST",
               body: form,
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         getDashboardData(access_token, type, date_to, date_from) {
           var details = {
             access_token: access_token,
             type: type,
             date_to: date_to,
             date_from: date_from,
           };

           return new Promise((resolve, reject) => {
             fetch(base_url + "admin/dashboard", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         getBorrowerDashboardData(access_token, type, date_to, date_from) {
           var details = {
             access_token: access_token,
             type: type,
             date_to: date_to,
             date_from: date_from,
           };

           //console.log("details:::" + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "borrower/dashboard", {
               method: "POST",
               body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //  console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }

         get_api_call(url) {
           //console.log("get_api_call:: url:: " + url);
           return new Promise((resolve, reject) => {
             fetch(base_url + url, {
               method: "GET",
               //body: JSON.stringify(details),
             })
               .then((response) => {
                 response.json().then((data) => {
                   //console.log("login:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }
         /* USED API END */

         backDatabase(access_token) {
           var form = new FormData();
           //  var details = {
           //    access_token: "c4ca4238a0b923820dcc509a6f75849b48a72691a83740e8005f7e480511d5f77542832d2728b2bf001885da89e40b7af214f068d62fd13129cec1dfcd716fb3",
           //    type: type
           //  };

           form.append("access_token", access_token);

           //console.log("details:::" + JSON.stringify(details));

           return new Promise((resolve, reject) => {
             fetch(base_url + "backup_download", {
               method: "POST",
               body: form,
             })
               .then((response) => {
                 response.json().then((data) => {
                   // console.log("BackUp Link:::" + data);
                   resolve(data);
                 });
               })
               .catch((err) => {
                 reject(err);
               });
           });
         }
       }
export const API_Service = new AccountServiceImpl();
