import mock from "./mock"
import "./apps/dataView"
import "./tables/aggrid"
import "./tables/investment"
import "./tables/fundingSource"
import "./tables/transactions"
import "./autoComplete/autoComplete"
import "./navbar/navbarSearch"
import "./navbar/navbarBookmarkSearch"
import "./auth/authentication"
import "./apps/email"
import "./apps/chat"
import "./apps/todo"
import "./apps/calendar"
import "./apps/emailList"
import "./apps/loanList"
import "./apps/userList"
import "./apps/roleList"
mock.onAny().passThrough()
