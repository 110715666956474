import mock from "../mock"
const users = [
  // {
  //   id: 268,
  //   username: "adoptionism744",
  //   avatar: require("../../assets/img/portrait/small/avatar-s-3.jpg"),
  //   email: "angelo@sashington.com",
  //   fname: "Angelo",
  //   lname: "Sashington",
  //   mobileno: "Sashington",
  //   dob: "28 January 1998",
  //   gender: "female",
  //   country: "Bolivia",
  //   role: "admin",
  //   max_rate:"4.5%",
  //   total_investors:"4",
  //   days:"35",
  //   status: "active",
  //   is_verified: true,
  //   loan_amount:"$110,000.00 / $0.00",
  //   min_amount:"$0.00",
  //   invest_total:"$945,695.00",
  //   department: "sales",
  //   funding_source: "XYZ Bank",
  //   company: "WinDon Technologies Pvt Ltd",
  //   mobile: "+65958951757",
  //   website: "https://rowboat.com/insititious/Angelo",
  //   languages_known: ["English", "Arabic"],
  //   contact_options: ["email", "message", "phone"],
  //   location: {
  //     add_line_1: "A-65, Belvedere Streets",
  //     add_line_2: "",
  //     post_code: "1868",
  //     city: "New York",
  //     state: "New York",
  //     country: "United States"
  //   },
  //   social_links: {
  //     twitter: "https://twitter.com/adoptionism744",
  //     facebook: "https://www.facebook.com/adoptionism664",
  //     instagram: "https://www.instagram.com/adopt-ionism744/",
  //     github: "https://github.com/madop818",
  //     codepen: "https://codepen.io/adoptism243",
  //     slack: "@adoptionism744"
  //   },
  //   permissions: {
  //     users: {
  //       read: true,
  //       write: false,
  //       create: false,
  //       delete: false
  //     },
  //     posts: {
  //       read: true,
  //       write: true,
  //       create: true,
  //       delete: true
  //     },
  //     comments: {
  //       read: true,
  //       write: false,
  //       create: true,
  //       delete: false
  //     }
  //   }
  // },
  {
    id: 269,
    username: "demodulation463",
    avatar: require("../../assets/img/portrait/small/avatar-s-2.jpg"),
    email: "rubi@ortwein.com",
    fname: "Rubi",
    lname: "Ortwein",
    mobileno: "7894561230",
    status: "blocked",
    country: "Syria",
    role: "user",
    max_rate:"4.5%",
    total_investors:"4",
    days:"35",
    
    is_verified: false,
    loan_amount:"$110,000.00 / $0.00",
    min_amount:"$0.00",
    invest_total:"$945,695.00",
    department: "development",
    funding_source: "XYZ Bank",
  },
  {
    id: 270,
    username: "undivorced341",
    avatar: require("../../assets/img/portrait/small/avatar-s-3.jpg"),
    email: "donnette@charania.com",
    name: "Donnette Charania",
    fname: "Donnette",
    lname: "Charania",
    mobileno: "7894561230",
    status: "blocked",

    country: "Iraq",
    role: "staff",
    max_rate:"4.5%",
    total_investors:"4",
    days:"35",
    status: "deactivated",
    is_verified: true,
    loan_amount:"$110,000.00 / $0.00",
    min_amount:"$0.00",
    invest_total:"$945,695.00",
    department: "sales",
    funding_source: "XYZ Bank",
  },
  {
    id: 271,
    username: "bumbo426",
    avatar: require("../../assets/img/portrait/small/avatar-s-12.jpg"),
    email: "ardith@duffett.com",
    name: "Ardith Duffett",
    fname: "Ardith",
    lname: "Duffett",
    mobileno: "7894561230",
    status: "active",

    country: "Estonia",
    role: "user",
    max_rate:"4.5%",
    total_investors:"4",
    days:"35",
    status: "active",
    is_verified: false,
    loan_amount:"$110,000.00 / $0.00",
    min_amount:"$0.00",
    invest_total:"$945,695.00",
    department: "sales",
    funding_source: "XYZ Bank",
  },
  {
    id: 272,
    username: "ectodactylism214",
    avatar: require("../../assets/img/portrait/small/avatar-s-16.jpg"),
    email: "antone@berman.com",
    name: "Antone Berman",
    fname: "Ardith",
    lname: "Berman",
    mobileno: "7894561230",
    status: "deactive",
    country: "India",
    role: "user",
    max_rate:"4.5%",
    total_investors:"4",
    days:"35",
    status: "blocked",
    is_verified: true,
    loan_amount:"$110,000.00 / $0.00",
    min_amount:"$0.00",
    invest_total:"$945,695.00",
    department: "sales",
    funding_source: "XYZ Bank",
  }
]

// GET DATA
mock.onGet("/api/users/list").reply(200, users)
