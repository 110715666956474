import mock from "../mock"
const data = [
  {
    id:"11",
    nickname:"BANK",
    type:"",
    name: "",
    amt_committed: "150000",
    cash_available: "1100",
    status: 'Active'
  },
  {
    id:"12",
    nickname:"IRA",
    type:"",
    name: "deposit Amount",
    amt_committed: "145200021",
    cash_available: "256985",
    status: 'Active'
  }
]
mock.onGet("/api/fundingSource/data").reply(200, {
  data
})
