import mock from "../mock"
const users = [
  {
    id: 12,
    template_name: "Investor Email",
    subject: "Successfully invertor paid amount.",
    message: "Hello, {investor_name}",
    status: "active",
  },
  {
    id: 13,
    template_name: "Borrower Email",
    subject: "Successfully invertor paid amount.",
    message: "Hello, {borrower_name}",
    status: "deactivated",
  }
]

// GET DATA
mock.onGet("/api/email/list").reply(200, users)
