import mock from "../mock"
const roles = [
  {
    id: 12,
    name: "User 1",
    email: "user1@semble.com",    
    status: "active"
  },
  {
    id: 13,
    name: "User 2",
    email: "user2@semble.com",
    status: "blocked"
  },
  {
    id: 14,
    name: "User 3",
    email: "user3@semble.com",    
    status: "active"
  },
  {
    id: 15,
    name: "User 4",
    email: "user4@semble.com",
    status: "blocked"
  },
  {
    id: 16,
    name: "User 5",
    email: "user5@semble.com",
    status: "deactivated"
  },
  {
    id: 15,
    name: "User 6",
    email: "user6@semble.com",
    status: "active"
  },
]

// GET DATA
mock.onGet("/api/user/list").reply(200, roles)
