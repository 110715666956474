import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  /*
  ||@Admin Home Nevbar menu Items
  */

  /*
  ||@Admin Nevbar Menu Items
  */
  {
    type: "groupHeader",
    groupTitle: "ADMIN",
  },
  {
    id: "eCommerceDash",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/dashboard",
  },
  {
    id: "loanAdmin",
    title: "Loan Admin",
    type: "item",
    icon: <Icon.Archive size={20} />,
    permissions: ["admin", "editor"],
    // navLink: "/admin/loans/list"
    navLink: "/admin/loans/index",
  },
  // {
  //   id: "loanCreate",
  //   title: "Loan Create",
  //   type: "item",
  //   icon: < Icon.Archive size = {20}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/admin/loans/create"
  // },
  {
    id: "investors",
    title: "Investors",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/investor/index",
  },
  {
    id: "borrowers",
    title: "Borrowers",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/borrower/index",
  },

  //   {
  //     id: "investors",
  //     title: "Investors",
  //     type: "collapse",
  //     icon: < Icon.User size = {
  //       20
  //     }
  //     />,
  //     // badge: "warning",
  //     // badgeText: "2",
  //     children: [{
  //         id: "analyticsDash_s",
  //         title: "List",
  //         type: "item",
  //         icon: < Icon.Circle size = {
  //           12
  //         }
  //         />,
  //         permissions: ["admin", "editor"],
  //         navLink: "/admin/investor/list"
  //       },
  //       {
  //         id: "eCommerceDash_a",
  //         title: "View",
  //         type: "item",
  //         icon: < Icon.Circle size = {
  //           12
  //         }
  //         />,
  //         permissions: ["admin"],
  //         navLink: "/admin/investor/view"
  //       },
  //       {
  //         id: "eCommerceDash_b",
  //         title: "Edit",
  //         type: "item",
  //         icon: < Icon.Circle size = {
  //           12
  //         }
  //         />,
  //         permissions: ["admin"],
  //         navLink: "/admin/investor/edit"
  //       }
  //     ]
  //   },
  //   {
  //     id: "borrowers",
  //     title: "Borrowers",
  //     type: "collapse",
  //     icon: < Icon.User size = {
  //       20
  //     }
  //     />,
  //     // badge: "warning",
  //     // badgeText: "2",
  //     children: [{
  //         id: "analyticsDash_s",
  //         title: "List",
  //         type: "item",
  //         icon: < Icon.Circle size = {
  //           12
  //         }
  //         />,
  //         permissions: ["admin", "editor"],
  //         navLink: "/admin/borrower/list"
  //       },
  //       {
  //         id: "eCommerceDash_c",
  //         title: "View",
  //         type: "item",
  //         icon: < Icon.Circle size = {
  //           12
  //         }
  //         />,
  //         permissions: ["admin"],
  //         navLink: "/admin/borrower/view"
  //       },
  //       {
  //         id: "eCommerceDash_d",
  //         title: "Edit",
  //         type: "item",
  //         icon: < Icon.Circle size = {
  //           12
  //         }
  //         />,
  //         permissions: ["admin"],
  //         navLink: "/admin/borrower/edit"
  //       }
  //     ]
  //   },
  {
    id: "userSettings",
    title: "User Setting",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/user/setting",
  },

  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/users/index",
  },

  {
    id: "email",
    title: "Email Template",
    type: "item",
    icon: <Icon.Mail size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/email/list",
  },
  {
    id: "listingManager",
    title: "Listing Manager",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/listing-manager",
  },
  {
    id: "manageListing",
    title: "Manage Listing",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/manage-listing",
  },

  /*
  ||@Investors Navbar menu items
  */
  {
    type: "groupHeader",
    groupTitle: "INVESTOR",
  },
  {
    id: "investorDash",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    // navLink: "/investor"
    navLink: "/investor/dashboard",
  },
  {
    id: "investorLoans",
    title: "Loans",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/investor/loans",
  },
  {
    id: "investorCommitments",
    title: "Commitments",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/investor/commitments",
  },
  // {
  //   id: "investorFundingSources",
  //   title: "Funding Source",
  //   type: "item",
  //   icon: <Icon.CheckCircle size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/investor/funding-sources",
  // },
  {
    id: "investorInvestments",
    title: "Investments",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/investor/investment",
  },

  // {
  //   id: "investorTransaction",
  //   title: "Transactions",
  //   type: "item",
  //   icon: <Icon.CheckCircle size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/investor/transaction",
  // },

  /*
  ||@Borrowers Navbar menu items
  */
  {
    type: "groupHeader",
    groupTitle: "BORROWER",
  },
  {
    id: "borrowerDash",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/borrower/dashboard",
  },
  {
    id: "borrowerLoans",
    title: "Loans",
    type: "item",
    icon: <Icon.Archive size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/borrower/loans/index",
  },
  {
    id: "borrowerCommitments",
    title: "Commitments",
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/borrower/commitments/index",
  },
  {
    id: "borrowerInvestments",
    title: "Investments",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/borrower/investments/index",
  },
  //   {
  //     id: "investorStatement",
  //     title: "Statement",
  //     type: "item",
  //     icon: < Icon.Mail size = {
  //       20
  //     }
  //     />,
  //     permissions: ["admin", "editor"],
  //     // navLink: "/investor-statement"
  //   },
  //   {
  //     id: "investorMyaccount",
  //     title: "My Account",
  //     type: "item",
  //     icon: < Icon.Settings size = {
  //       20
  //     }
  //     />,
  //     permissions: ["admin", "editor"],
  //     // navLink: "/investor-myaccount"
  //   },

  // {
  //   id: "listingManager",
  //   title: "Listing Manager",
  //   type: "item",
  //   icon: <Icon.Grid size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/admin/listing-manager-n",
  // },
  // {
  //   id: "listingManagertwo",
  //   title: "Listing Manager 2",
  //   type: "item",
  //   icon: <Icon.Grid size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/admin/listing-manager-tn",
  // },
];

export default navigationConfig;
