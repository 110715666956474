import mock from "../mock"
const data = [
  {
    id:"1112",
    amount:"$500,000.00",
    pna_status:"",
    firstname: "Vaill",
    lastname: "Vaill",
    company: "Bank",
    address: "14225 Hancock Dr",
    city: "Anchorage",
    country: "Anchorage",
    state: "AK",
    zip: "99515",
    phone: "907-345-0962",
    fax: "907-345-1215",
    email: "essie@vaill.com",
    web: "http://www.essievaill.com",
    followers: 3574,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '3.5%',
    status: 'Active'
  },
  {
    id:"1123",
    amount:"$100,000.00",
    pna_status:"SIGNED",
    firstname: "Roudabush",
    lastname: "Roudabush",
    company: "IRA",
    address: "2202 S Central Ave",
    city: "Phoenix",
    country: "Maricopa",
    state: "AZ",
    zip: "85004",
    phone: "602-252-4827",
    fax: "602-252-4009",
    email: "cruz@roudabush.com",
    web: "http://www.cruzroudabush.com",
    followers: 6548,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '3%',
    status: 'Active'
  },
  {
    id:"1125",
    amount:"$700,000.00",
    pna_status:"SIGNED",
    firstname: "Tinnes",
    lastname: "Tinnes",
    company: "BANK",
    address: "28 W 27th St",
    city: "New York",
    country: "New York",
    state: "NY",
    zip: "10001",
    phone: "212-889-5775",
    fax: "212-889-5764",
    email: "billie@tinnes.com",
    web: "http://www.billietinnes.com",
    followers: 3536,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '3.5%',
    status: 'Active'
  },
  {
    id:"1126",
    amount:"$400,000.00",
    pna_status:"",
    firstname: "Mockus",
    lastname: "Mockus",
    company: "BANK",
    address: "286 State St",
    city: "Perth Amboy",
    country: "Middlesex",
    state: "NJ",
    zip: "08861",
    phone: "732-442-0638",
    fax: "732-442-5218",
    email: "zackary@mockus.com",
    web: "http://www.zackarymockus.com",
    followers: 1497,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '3.5%',
    status: 'Active'
  },
  {
    id:"1130",
    amount:"$900,000.00",
    pna_status:"",
    firstname: "Rosemarie",
    lastname: "Fifield",
    company: "IRA",
    address: "3131 N Nimitz Hwy  #-105",
    city: "Honolulu",
    country: "Honolulu",
    state: "HI",
    zip: "96819",
    phone: "808-836-8966",
    fax: "808-836-6008",
    email: "rosemarie@fifield.com",
    web: "http://www.rosemariefifield.com",
    followers: 4812,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '4.5%',
    status: 'Active'
  },
  {
    id:"1135",
    amount:"$112,000.00",
    pna_status:"SIGNED",
    firstname: "Bernard",
    lastname: "Laboy",
    company: "BANK",
    address: "22661 S Frontage Rd",
    city: "Channahon",
    country: "Will",
    state: "IL",
    zip: "60410",
    phone: "815-467-0487",
    fax: "815-467-1244",
    email: "bernard@laboy.com",
    web: "http://www.bernardlaboy.com",
    followers: 6891,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '3.5%',
    status: 'Active'
  },
  {
    id:"1140",
    amount:"$114,000.00",
    pna_status:"SIGNED",
    firstname: "Sue",
    lastname: "Haakinson",
    company: "IRA",
    address: "9617 N Metro Pky W",
    city: "Phoenix",
    country: "Maricopa",
    state: "AZ",
    zip: "85051",
    phone: "602-953-2753",
    fax: "602-953-0355",
    email: "sue@haakinson.com",
    web: "http://www.suehaakinson.com",
    followers: 5787,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '3.5%',
    status: 'Active'
  },
  {
    id:"1142",
    amount:"$189,000.00",
    pna_status:"",
    firstname: "Valerie",
    lastname: "Pou",
    company: "CASH",
    address: "7475 Hamilton Blvd",
    city: "Trexlertown",
    country: "Lehigh",
    state: "PA",
    zip: "18087",
    phone: "610-395-8743",
    fax: "610-395-6995",
    email: "valerie@pou.com",
    web: "http://www.valeriepou.com",
    followers: 8990,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '3.5%',
    status: 'Active'
  },
  {
    id:"1151",
    amount:"$500,000.00",
    pna_status:"",
    firstname: "Lashawn",
    lastname: "Hasty",
    company: "BANK",
    address: "815 S Glendora Ave",
    city: "West Covina",
    country: "Los Angeles",
    state: "CA",
    zip: "91790",
    phone: "626-960-6738",
    fax: "626-960-1503",
    email: "lashawn@hasty.com",
    web: "http://www.lashawnhasty.com",
    followers: 2131,
    payments: '$100.00',
    investment_amt: '$95.00',
    rate: '2.5%',
    status: 'Active'
  }
]
mock.onGet("/api/investment/data").reply(200, {
  data
})
